.totalText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: #2B2B2B;
}
.arrivalText
{
    
    font-size: 20px !important;
    font-weight: bold !important;
    padding-bottom: 12%;
    padding-top: 3%;
    padding-left: 1%;
}
// my changes
// .pass-frm{
//     width: 310px !important;
// }
// .chech-lang{
//     width: 155px !important;
// }
// .spl-frm{
//     width: 100% !important;
// }
.MuiFormGroup-root .MuiFormControl-root.MuiTextField-root, .dropdown_formcontrol, .MuiFormGroup-root .react-tel-input{
    border: 1px solid #b3b3b3 !important;
}
.MuiFormGroup-root .react-tel-input .flag-dropdown{
    border-right: 1px solid #b3b3b3;
}
// my changes
.arrivel-info{
    display: flex;
    flex-direction: row;
}
.whole-con{
    display: flex;
    flex-direction: row;
}
.mainstop{

}
.location_innerbox{
    // justify-content: flex-start;
    // flex-direction: row;
    //  flex-wrap: nowrap;
    // width: 90%;
    // border: 1px solid #cacaca;
    // // margin: 10px auto;
    // border-radius: 5px;
}
.location_midpoint{
    display: none;
}
.total_fare {
    text-align: center;
    padding: 0;
    background-color: #c67b15;
    color: #fff;
    /* margin: 10px auto; */
    width: 100%;
    border-radius: 0px 0px 5px 5px;
}
.total_fare p {
    color: #000;
    font-size: 18px;
    padding: 10px 15px;
    font-weight: bold;
    text-align: right;
}
.arrival-con{
    // display: flex;
    // flex-direction: row;
}
// .arr-method{
//     width: 130px !important;
// }
// .cruise{
//     width: 340px !important;
// }
// .disembarkment{
//     width: 220px !important;
// }
// .buffer{
//     width: 220px !important;
// }
.coupon-con{
    display: block !important;
    margin-top: 10px !important;
}
.add-more-btn{
    background: #fff !important;
}
.point-img{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ff0000;
}
.point-img img{
    width: 23px;
    margin: 0px 5px;
}
.pass-box-p{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.pass-box-p div{
   
}
.pass-frm{
    margin-bottom: 15px;
}
.spl-frm{
    margin-bottom: 15px;
}
.pass-border-box{
    padding: 0px !important;
}
.pass-holder{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.spl-frm-h{
    height: 70px !important;
}
.country-h{
    height: 10px;
}
.from-txt{
    font-size: 13px !important;
    padding-left: 15px;
    color: rgb(0, 0, 0);
}
.to-txt{
    font-size: 13px !important;
    padding-left: 15px;
    color: rgb(0, 0, 0);
}
.itinary-hold{
    box-shadow: none !important;
    border: 1px solid #cbcbcb;
    border-radius: 7px !important;
}
.pangr-btn{
    margin-bottom: 20px !important;
}
.input-m-h > div > .react-tel-input{
    // height: 38px !important;
}
@media screen and(max-width: 786px){
    .arrival-con{
        flex-direction: column;
    }
    .cruise {
        width: 100% !important;
    }
    .disembarkment {
        width: 100% !important;
    }
    .card_content{
        // width: 310px;
    }
    .location_innerbox{
        // flex-wrap: nowrap;
        // width: 80%;
    }
    .container_topspace{
        flex-direction: column-reverse!important;
    }
    .total_fare{
        // width: 70%;
    }
    .cam-icon{
        right: 30px !important;
    }
    .mob-pro .shadow_box{
        margin-bottom: 0px !important;
    }
}