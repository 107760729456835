.subheading
{
    font-size: 35px;
    color: #333333;
    display: flex ;
    font-family: 'Nunito' !important;
}
.underline
{
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold;
    text-underline-position: under;
    margin-right: 10px;
    font-size :x-large;
    margin-top: 8px !important;
    margin-bottom: 10px !important;
}
// .tripBox
// {
//     padding-left: 3em;
// }

// .Mui-selected
// {
//     background-color: #333333 !important;
//     color: white !important;
//     border-radius: 15px !important;
    
// }
// .css-1h9z7r5-MuiButtonBase-root-MuiTab-root
// {
//     background-color: #F6F6F6;
//     border-radius: 15px !important;
//     margin-right: 5em;
//     padding-left: 50px;
//     padding-right: 50px;
// }
.tabStyle
{
    // font-family: 'Nunito' !important;
    // font-weight: bold !important;
    // font-size: 15px !important;
    // letter-spacing: 1.25px;
    // border-radius: 15px !important;
    // background-color: #F6F6F6 !important;
    // color: 'white' !important;
    max-width: '200px' !important;
    
}
.newbooking
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 12px !important;
    letter-spacing: 1.25px;
    border-radius: 4px;
    height: 30px !important;
}
.search
{
    margin-left: 5px !important;
}
// .css-19kzrtu
// {
//     padding-left: 0px !important;
// }
.tablecell
{
    font-family: 'Nunito'!important;
    color: #333333;
    font-weight: bold !important;
    opacity: 0.5;
    font-size: 20px !important;
}
.modalHeadText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #2B2B2B;
}
.tablevalue
{
    font-family: 'Nunito'!important;
    color: #333333;
    font-weight: 600 !important;
    font-size: 22px !important;
    padding: 35px !important;
}
.modalHeadStyle
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 16px !important;
    color: white;
    padding-left: 3%;
}
.modalDate
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: white;
    opacity: 0.77;
    padding-right: 3%;
}
.vehiclename
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    color: #fff;
    font-size: 16px !important;
}
.companytext
{
    font-family: 'Nunito' !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #2B2B2B;
}
.storeText
{
    font-family: 'Nunito' !important;
    font-size: 12px !important;
    color: #2B2B2B;
    padding-right: 20px;
}
.namesText
{
    font-family: 'Nunito'!important;
    font-weight: 600!important;
    font-size: 12px !important;
    color: #2B2B2B;
    padding-right: 3px;
}

.newText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #2B2B2B;

}
.completeText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #57C007;

}
.modalScroll
{
   
    overflow-x: hidden ;

}
.css-1piqeeu
{
    border: 0px !important;
}
// ::-webkit-scrollbar {
//     width: 0px;
//     background: transparent; /* make scrollbar transparent */
// }
.capacityText
{
    font-family: 'Nunito'!important;
    font-size: 12px !important;
    color: #2B2B2B;
}
.priceText
{
    font-family: 'Nunito'!important;
    font-size: 13px !important;
    color: #2B2B2B;
}

.MuiDataGrid-columnHeaderTitle{
    font-family: 'Nunito'!important;
    font-size: 12px !important;
    font-weight: bold !important;
}
