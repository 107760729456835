.sumheading
{
    font-size: 65px;
    padding-left: 1em;
    color: #333333;
    display: flex ;
    font-family: 'Nunito' !important;
    font-weight: bold;
    text-decoration: underline #FFC300;
    text-underline-position: under;
    
}


.dotted {
    border-bottom: 1px dotted red;
    height: 11px;
    position: absolute;
}

.loctext p
{
    color: #333333;
    font-size: 14px !important;
    font-family: 'Nunito' !important;
    line-height: normal;
    // font-weight: bold;
}
.loctext p .listitem_value{
    color: #333333 !important;
  
}
.mapText
{
    font-size: 12px;
    font-family: 'Nunito';
    font-weight: bold;
    color: #fff;
    padding-left: 20px;
    //padding-right: 20px;
}
.loctextbold
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    color: #333333;
    font-size: 17px !important;
}
.timelinetext {
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    color: #333333;
    font-size: 14px !important;
}

.timelinesubtext {
    font-family: 'Nunito'!important;
    font-weight: 400 !important;
    color: #333333;
    font-size: 12px !important;
}

.addtext

{
    color: #333333 !important;
    font-size: 16px !important;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    padding-top: 5px;
}
.descriptionText
{
   color: #333333;
   font-size: 12px !important;
   font-family: 'Nunito' !important; 
   font-weight: 800 !important;
}

.pricetext
{
    font-size: 25px !important;
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold;
    font-weight: bold !important;
}

.pricetexttime
{
    font-size: 25px !important;
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
}

.fontText {
    font-family: 'Nunito'!important;
}
.nameText
{
    font-size: 19px !important;
    color: #333333;
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    letter-spacing: 1.75px;

}
.classText
{
    font-family: 'Nunito'!important;
    // font-weight: 600 !important;
    color: #333333;
    font-size: 14px !important;
}
.capacity
{
    font-family: 'Nunito'!important;
    color: #333333;
    font-size: 20px !important;
    //padding-right: 1em;
}
.capacityCount
{
    font-family: 'Nunito'!important;
    color: #333333;
    font-size: 20px !important;
    opacity: 50%;
}
.modalHeader
{
    font-family: 'Nunito' !important;
    font-size: 20px !important;
    font-weight: bold !important;
    //margin-left: 30% !important;
}

.titleText{
    font-family: 'Nunito' !important;
    font-size: 16px !important;
    font-weight: bold !important;
}



.buttonText
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 12px !important;
    color: #FFFFFF;
}

.guestcheckout
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #FFFFFF;
}

.pac-container { z-index: 100000; }
.MuiAccordion-root:before
{
display: none !important;
}
.vertical
{
    
    border-left:'1px solid red',
    height='3em',
margin-left='2.1em'
}

.MuiButton-filledSizeSmall {
    min-width: none !important;
}


::file-selector-button {
    display: none;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
 }
//  my changes
.book-det{
    background-color: #FFC300;
}
.srch-holder{
    padding: 0px;
}
.location_innerbox{
    background-color: #fff;
    border-radius: 6px;
    padding: 1px 0px;
}
.car-sel{
    padding-left: 100px;
    padding-right: 100px;
}
.one-point-con{
    // display: flex;
    // flex-direction: column;
}
.one-way-con{
    // padding: 0px !important;
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: space-between;
    // flex-wrap: nowrap;
    // width: 100%;
}
.point-way-con{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.one-way-h{
    text-align: left;
    font-size: 1rem;
    font-weight: bold !important;
    // padding-left: 30px;
    // padding-top: 10px;
}
.ret-det-h{
    text-align: left !important;
    font-size: 1rem;
    font-weight: bold !important;
    padding-left: 30px;
}
// .ret-con-w{
//     margin: auto;
//     width: 90% !important;
// }
.ret-d-f{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding: 1px 0px;
    border-radius: 5px;
    font-size: 14px;
}
.ret-con-w{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: auto;
}
.ret-loc{
    width: 90%;
    margin-right: 10px;
}
.ret-d-loc{
    width: 90%;
    margin-right: 10px;
}
.ret-d-con{
    // width: 48%;
    // margin-right: 10px;
}
.ret-t-con{
    width: 46%;
}
.ret-holder{
    // width: 90%;
    // margin: auto;
    // padding: 0px !important;
}
.ret-loc-point{
    border-radius: 0px;
}
.point-ret-d-m{
    // padding-bottom: 10px;
    font-size:14px !important;
    font-weight: 600 !important;
    line-height: normal!important;
}
.ret-pick-drop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.sum-p-holder{
    min-width: 1527px !important;
}
.sum-p-holder{
    width: inherit;
}
.one-way-con{

}
.sum-y-w{
     padding-left: 8px !important;
}
.sum-inside{
    padding: 10px 0;
}
@media screen and(max-width: 786px){
    .tripsummary_holder{
        // width: 97%;
    }
    .sum-main-hol{
        // width: 98%;
    }
    .list-n {
        // width: 175px;
    }
    .car-sel {
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .tripsummary_holder{
        // width: 422px;
    }
    .sum-p-holder {
        // width: 28% !important;
    }
    .sum-inside{
        // flex-direction: column;
    }
    .sum-main-hol{
        // width: inherit;
    }
    .one-point-con {
        // display: flex;
        // flex-direction: column;
    }
    .one-way-con{
        // flex-direction: column;
    }
    .sum-p-holder{
        // flex-direction: column !important;
    }
    .sum-y-w{
        // width: 28% !important;
    }
    .ret-con-w{
        // flex-direction: column !important;
    }
    .loc-p-n{
        // flex-wrap: wrap;
    }
    .sum-p-d{
        // width: 46%;
        // margin-right: 12px;
    }
    .sum-p-t{
        // width: 46%;
    }
}