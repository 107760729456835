
.loginBackground {
    background: url(../../assets/images/logi.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
}
.log-bg-con{
    display: flex !important;
    flex-direction: row !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    align-items: stretch;
}
.login {
    background: white;
    padding: 20px;
    box-shadow: 0 0 15px 0px #ababab;
    border-radius: 20px;
    margin: auto;
    margin-top: -10%;
}
.log-bg-img{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.log-bg-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
// .log-frm{
//     flex-direction: column !important;
//     justify-content: center;
//     align-items: center;
//     width: 700px !important;
// }
.login_title{
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 20px;
}
.login-holder{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.log-input-con{
    display: flex;
    flex-direction: column;
}
.loginheading
{
    font-size: 22px;
    color: #333333;
    display: flex ;
    font-weight:700;
    font-family: 'Nunito' !important;
    font-weight: bold;
    padding-bottom: 0px !important;
}

.login_rightsec{
    flex-wrap: wrap;
    place-content: space-around;
    display: flex;
    align-content: space-around;
    justify-content: space-around;
}
button.btn_login{
    background-color: #c67b15;
    border-radius: 6px;
    padding: 10px 15px!important;
    margin: 0px;
    height: 45px!important;
}
button.btn_google_login.goog-btn{
    background-color: rgb(66, 134, 245) !important;
    border-radius: 6px;
    padding: 10px 15px!important;
    margin: 0px;
    height: 45px!important;
}
button.btn_google_login.goog-btn:hover{
    background-color: rgb(58, 107, 187) !important;
    padding: 10px 15px !important;
    height: 45px !important;
}
.forgotText
{
    font-size: 14px !important;
    color: '#333333';
    font-weight: 700;
    font-family: 'Nunito';
}
.loginButton
{
    font-family: 'Nunito';
    // font-weight: bold !important;
    font-size: 12px !important;
    color: #FFFFFF;
    text-transform: none !important;
}
.accountText
{
    //font-weight: 600 !important;
    font-size: 13px !important;
    font-family: 'Nunito';
}
.googleButton
{
    font-family: 'Nunito' !important;
    font-weight: bold;
    font-size: 12px !important;
    color: #FFFFFF;
    text-transform: none !important;
}
.log-frm{
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}
.log-input-frm{
    width: 100%;
}
.login_rightsec{
    width: 100%;
    margin-top: 30px !important;
}
.log-frm-holder{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow-y: auto;
    background-image: url("../../assets/images/bg-design.png");

    background-repeat: no-repeat;
    background-position: bottom right;
    background-attachment: fixed;
    background-size: 47%;
    flex-wrap: wrap;
}
.log-frm-con{
    box-shadow: 0 0 15px 0 #0000004f;
    border-radius: 10px;
    margin: 20px auto;
    overflow: hidden;
    padding: 30px;
    width: 360px;
    background-color: #fff;
}

.log-input-frm .formText{
    border-radius: 6px !important;
}
.goog-btn{
    background-color: rgb(66, 134, 245) !important;
}
.btn_login:hover{
    background-color: #b77a2a !important;
}
.log-frm-con .shadow_box{
    padding-top: 40px;
}