body{
    font-family: Nunito;
}
.homeBackground {
    background: url(../../assets/images/home.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    // height: 90vh;
    position: relative;
    width: 42%;
    height: 100%;
}


.homeHeader{
    display: flex;
    height:auto ;
    flex-direction:row;
    background-color:#c67b15;
    justify-content:end;
    align-items:center;
     padding-left: 3%;
     padding-right: 5%;
}

.siteHeader{
    display: flex;
    height:auto ;
    flex-direction:row;
    background-color:#c67b15;
    justify-content:end;
    align-items:center;
     padding-left: 3%;
     padding-right: 5%;
}
.mainHeader{
    position: absolute;
    background-color: transparent;
    padding: 0px 0px;
    width: 100%;
    z-index: 1;
}

.bg-orange {
    background-color: transparent;
    
    position: absolute;
    bottom: 15px;
}
.sub-box{
    position: relative;
    padding: 10px;
    height: 150px;
}
.sub-box::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    padding: 1px;
    background: linear-gradient(130deg, #ffffff, #f6b73c);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
#sec-con {
  
}

.sub-box .imges {
    width: 40px;
    height: 60px;
    margin: 0px auto;
}

.sub-box .imges img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.sub-con {
    margin-left: 10px;
    width: 88%;
    text-align: center;
    margin: 0px auto;
}
.sub-con h6 {
    position: relative;
    margin: 0px;
    margin-bottom: 5px;
    font-size: 15px;
    color: #ffffff;
    font-weight: normal;
}
.sub-con p {
    font-size: 12px;
    margin: 0px;
    color: #fff;
}

.sub-box-3 .imges-3{
    width: 100px;
    padding: 0px 15px;
}
.sub-box-3 .imges-3 img{
    width: 100%;
}
.sub-box-3 h4{
    font-size: 23px;
    margin-top: 5px;
    font-weight: bold !important;
}
.sub-box-3 p{
    font-size: 13px;
}
.sub-box-3{
   
    padding: 20px;
    border: 1px solid #c07a1b;
    border-radius: 20px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: 0px 0px 13px #e7e7e7;
}
.sub-box-3:hover{
background-color: #c07a1b;
color:#000;
}
.text-center{
    text-align: center;
}
.sec-3-head{
    margin-bottom: 25px;
    margin-top: 50px !important;
    text-align: center;
    font-size: 25px;
}
.why-chooseus{
    position: relative;
    overflow: hidden;
    padding: 100px 0px;
}
.perfect-ride{
    position: relative;
    overflow: hidden;
    padding: 100px 0px 50px 0px;
    margin: 50px 0px;
}
.bg-secondary{
    background-color: #ffffff;
    color: #fff;
  
}
.empty-back {
    display: none;
    position: absolute;
    right: 0;
    top: 0px;
    width: 700px;
    height: 420px;
    transform: rotateZ(115deg);
    background: #c07a1b;
}
.pre_head{
    font-size: 15px;
    color: #c07a1b;
    margin: 0;
    letter-spacing: 1px;
}
.section_head{
    font-size: 26px;
    color: #000;
    margin: 0;
    margin-bottom: 10px;
    font-weight: bold !important;

}
.para{
    color: #000;
    width: 400px;
    padding-top: 15px;
    font-size: 15px;
}
.ride-content{
    color: #000;
    padding-left: 200px;
}
.ride-content li{
    list-style-type: none;
    padding: 4px 0px;
    font-size: 15px;
}
.ride-content li span{
    margin-right: 10px;
}
.ride-content ul{
    padding-left: 0px;
    padding-top: 10px;
}
.car-img {
    position: absolute;
    height: 300px;
    left: 70px;
    width: 650px;
}
.car-img img{
    width: 100%;
    height: 100%;
    // object-fit: contain;
}
// my changes
.headerCon{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100vh;
    justify-content: space-between;
    // overflow: hidden;
}
#sec-con{
    padding: 0px 30px;
}
#simple-tab-0{
    border-radius: 10px 0px 0px 0px;
}
#simple-tab-1{
    border-radius: 0px 10px 0px 0px;
}
.star-dec{
    position: relative;
}
.star-one{
    position: absolute;
    top: -97px;
    right: 170px;
    width: 20px;
}
.star-one img{
    width: 100px;
}
.star-two{
    position: absolute;
    top: -56px;
    right: 190px;
}
.star-two img{
    width: 70px;
}
.multistar{
    position: absolute;
    top: 180px;
    left: 230px;
}
// best ride
.best-ride{
    padding: 50px 0px;
}
.best-ride-con{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.best-ride-img{
    width: 93px;
    padding: 20px 0px;
}
.best-ride-con img{
    width: 100%;
}
.best-ride-con p{
    font-size: 11px;
    text-align: center;
}
.search-bar{
    position: absolute;
    bottom: 15px;
}
.header-right{
    position: relative;
    width: 57%;
}
.Header-h{
    font-size: 28px;
    width: 380px;
    font-weight: bold !important;
    padding-top: 50px;
    padding-left: 40px;
}
.header-sm-img{
    position: relative;
}
.vector-one{
    // position: absolute;
    // top: 10px;
    width: 280px;
    // left: 40px;
    margin-left: 40px;
}
.vector-one img{
    width: 100%;
}
.vector-two{
    // position: absolute;
    // top: 40px;
    width: 240px;
    // left: 40px;
    margin-left: 40px;
}
.vector-two img{
    width: 100%;
    overflow: hidden;
}
.header-car{
    overflow: hidden;
}
.header-car img{
    // display: none;
    width: 100%;
    position: relative;
    left: 420px;
    top: 50px;
}
.ride-bg{
    position: absolute;
    top: -50px;
    width: 520px;
}
.ride-bg svg{
    width: 100%;
}
.header-content{
    position: absolute;
    top: 40px;
}
.ride-card-con{
    background-color: #fff;
    box-shadow: 0px 3px 5px #8e8e8e;
    display: flex;
    flex-direction: column;
    border-top-right-radius: 80px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
}
.ride-head p{
    text-align: left;
    color: #c67b15;
    letter-spacing: 1px;
    margin-bottom: 0px;
}
.ride-head h4{
    text-align: left;
    font-size: 23px;
    font-weight: bold !important;
    margin-top: 0px;
}
.ride-img {
    margin: 15px auto;
    width: 200px;
    height: 120px;
}
.ride-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.car-ride-content {
    padding: 5px 20px 0px 20px;
}
.car-ride-content h4{
    margin-top: 0px;
    font-weight: bold !important;
    text-align: center;
}
.ride-pos-rel{
    margin: 0px 10px;
    padding: 30px 0px 0px 0px;
}
.ride-card-con {
    width: 95%;
    height: 100%;
    margin: 0px auto;
}
.ride-star-con{
    width: 13px;
    height: 13px;
    display: flex;
}
.ride-star-con img{
    width: 100%;
    margin: 0px 1px;
}
.star-holder{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.p-star p{
    font-size: 13px;
    margin-bottom: 0px;
}
.star-num{
    font-size: 10px;
    padding-left: 5px;
    color: #8a8a8a;
}
.ride-star-btn a{
    background: #c67b15;
    color: #000;
    text-decoration: none;
    padding: 8px 25px;
    border-radius: 3px;
    font-size: 13px;
}
.car-bg{
    background-image: url('../../assets/images/light_car_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    width: 100%;
    position: absolute;
    top: 230px;
    z-index: -1;
}
.car-bg-con{
    position: relative;
}
.Best-cab{
    padding: 10px 0px 188px 0px;
}
.date-rtrn div > .react-datepicker-wrapper > .react-datepicker__input-container > .MuiFormGroup-root .MuiFormControl-root.MuiTextField-root, .MuiFormGroup-root .react-datepicker__input-container .MuiFormControl-root.MuiTextField-root{
    height: 34px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and(max-width: 786px) {
    .ride-bg{
        width: 90%;
    }
    .car-img {
        height: auto;
        left: -10px;
        width: 100%;
        top: 140px;
    }
    .para{
        width: auto;
    }
    .car-bg{
        display: none;
    }
    .ride-content{
        margin-top: 350px !important;
        padding-left: 0px;
    }
    .star-one {
        top: -100px;
        right: 20px;
        width: 50px;
    }
    .star-one img {
        width: 70px;
    }
    .star-two {
        top: -50px;
        right: 60px;
    }
    .star-two img {
        width: 50px;
    }
    .multistar {
        top: 160px;
        left: 230px;
    }
    .perfect-ride{
        margin: 0px;
    }
    .best-ride-con{
        flex-wrap: wrap;
    }
    .best-ride-img{
        width: 150px;
    }
    .best-ride-con img {
        width: 100%;
    }
    .sub-box{
        margin: 10px 0px;
        height: auto;
    }
    .sub-box::before{
        height: 120px;
        background: linear-gradient(130deg, #f6b73c63, #f6b73c);
    }
    .headerCon{
        flex-direction: column;
 
        height: 100%;
        padding-bottom: 100px;
    }
    .mainHeader{
        position: relative;
    }
    .header-content{
        position: relative;
        top: 0px;
        height: auto;
    }
    .sub-con p{
        display: none;
    }
    .homeBackground{
        width: 100%;
        // background: none;
        height: 100%;
 
        position: absolute;
        padding-bottom: 120px;
    }
    .bg-orange{
        position: relative;
        padding-top: 0px;
        top:0px;
    }

    .search-bar{
        position: relative;
        padding-top: 0px;
        bottom: 0px;
    }
    .user-navbarmenu .loginmenu{
        font-size: 12px;
    }
    .user-navbarmenu .loginmenu svg{
        width: 0.6em;
        height: 0.6em;
    }
    .user-navbarmenu .registermenu{
        font-size: 12px;
    }
    .user-navbarmenu .registermenu button{
        font-size: 12px;
    }
    .user-navbarmenu .registermenu  svg{
        width: 0.6em;
        height: 0.6em;
    }
    #sec-con{
        padding: 0;
    }
    .header-car img{
        display: none;
    }
    .Header-h{
        padding-top: 10px;
        padding-left: 20px;
        margin-top: 10px;
        width: auto;
    }
    .vector-one, .vector-two{
        margin-left: 20px;
    }
    .sub-con h6, .sub-con p{
        color: #ffffff;
    }
    .header-right{
        width: 100%;
    }
    .service{
        display: block;
        // margin-top: 100px;
        height: auto !important;
        position: absolute;
        bottom: 0;
    }
}
// @media screen and(max-width: 993px) {
//     .ride-bg {
//         width: 60%;
//         left: 100px;
//     }
//     .car-img {
//         height: 210px;
//         left: 230px;
//         width: 460px;
//         top: 140px;
//     }
//     .star-one {
//         top: -40px;
//         right: 260px;
//         width: 50px;
//     }
//     .star-two {
//         top: 10px;
//         right: 290px;
//     }
// }