.payText
{
    
    font-size: 13px !important;
    font-weight: bold !important;
    color: #333333;
    padding-left: 1%;
}
.Mui-checked
{
    color: #333333 !important;
}
.radioText
{
    font-size: 20px !important;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    padding-left: 5px !important;
}
.checkIcon
{
    color: white !important;
    background-color: #c67b15 !important;
    font-size: 20px !important;
    margin-left: '-13px' !important;
}

.card {
  background-color: white;
  padding: 10px;
  /* padding-top: 10px; */
  border-radius: 5px;
  /* width: 100%; */
  /* height: 30px; */
  border: 0.5px solid #afafaf;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  }
  .pay-button{
    padding: 0.7rem 2rem;
    width: 100%;
    margin: 1rem 0;
    color: white;
    font-weight: bold;
    font-size: medium;
    background-color: #556cd6;
    border: 0;
    border-radius: 5px;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    transition: box-shadow 500ms;
    cursor: pointer;
  }
  .pay-button:disabled{
    background-color: #afafaf;
    box-shadow: none;
    cursor: default;
  }
  .pay-button:disabled:hover{
    box-shadow: none;
  }
  .pay-button:hover{
    box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
  }
  // my changes
  .payment-h {
    font-size: 30px;
    font-weight: bold;
    padding-top: 15px;
    padding-left: 30px;
}
.coupon-p{
  font-size: 26px !important;
  font-weight: bold !important;
}
.pay-btn{
  padding: 10px 30px !important;
}
.paymnt-con{
 
}
.summry{
  // padding: 10px 0px !important;
}
.tot-f{
  padding:0px;
}
.total-fare{
  background-color: #afafaf;
}
.tot-pad{
  padding-top: 0px !important;
}
.tot-fare-con{
  background-color: #ffffff;
  padding: 0px !important;


}
.fare_list{
  display: flex;
   flex-direction: column;
   justify-content: space-between;
   padding:0px!important;
}
.fare_item{
  display: flex;
   flex-direction: row;
   justify-content: space-between;
   border: 1px solid #ddd;
    padding: 5px;
     border-radius: 5px;
     margin-bottom: 10px;
}
.fare_item.fare_discount{
background-color: #e0ffdf;

}

.tot-fare-h{
  text-align: left;
  font-size: 26px !important;
  font-weight: bold !important;
  padding: 0px 0px 0px 10px;
}
.pay-now-btn{
  margin-left: 10px !important;
}
.paymnt-con div{
  
}
.card-tot{
  display: flex;
  flex-direction: column;
  padding: 0px !important;
}
.summry{
padding: 0px !important;
}
.paymnt-con{
  padding: 0px !important;
}
.css-yzax2x {
  padding-bottom: 0px !important;
}
.tot-itinerary{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly;
}
.card-pad{
  padding: 0px !important;
}
.coupon-con{
  
}
@media screen and(max-width: 786px){
  .tot-itinerary{
    flex-direction: column;
  }
  .summry{
    flex-direction: column-reverse;
    width: 100% !important;
  }
  .paymnt-con {
    width: 100% !important;
}
.shadow_box .box_title p, .shadow_box .box_title p.MuiTypography-root{
  font-size: 23px !important;
}
.tot-f{
  width: 100%;
}
.tot-fare-con{
  width: 100%;
  margin-top: 0px;
}
.summry{
  padding: 0px !important;
}
.card_content {
  // width: 320px;
}
.itinary-hold{
  // width: 320px;
}
.card-tot {
  flex-direction: column;
}
.card-m-w{
  width: 100% !important;
}
}