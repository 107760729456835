.Loginheading
{
    font-size: 45px;
    color: #333333;
    text-underline-position: under;
    margin-right: 10px;
    font-family: 'Nunito' !important;
    font-weight: bold;
}
.guest-log-b{
    box-shadow: 0 0 15px 0 #0000004f;
    margin: 20px auto;
    padding: 10px 10px 40px 10px;
    border-radius: 10px;
    width: 30%;
}
.guest-log-b .shadow_box{
border:none;
}
.guest-box{
    padding-top: 0px !important;
}
.guest-in .formText{
    border-radius: 6px !important;
}
.guest-log-in{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.gog-btn-p > button.goog-bg{
    background-color: #4286f5 !important;
}
.gog-btn-p > button.goog-bg > p{
    color: #fff !important;
}
@media screen and(max-width: 786px){
    .guest-log-b{
        width: 100%;
    }
    .itinary-hold{
        width: 320px;
    }
}