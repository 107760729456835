.b2bHead
{
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #333333;
}
.cus-reg-con{
   display: flex;
}
.cus-reg-bg{
   
    height: 100vh;
    overflow: hidden;
}
.cus-reg-bg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.com-reg-side-h img{
    width: 50px;
}
.com-reg-side-h{
    padding-left: 20px;
    display: none;
}
.com-reg-side-h p{
    color: #c67b15;
    font-size: 25px !important;
    font-weight: 700;
}
.com-reg-side-h h2{
    font-size: 29px;
    font-weight: bold !important;
    padding-bottom: 10px;
}
.reg-input-pos{
    flex-direction: column;
}
.group_box{
padding: 20px;
}
.group_title{
font-size: 24px;
margin-top: 0;
}
.cus-regfrm{
   
  
    height: 100vh;
    overflow-y: auto;
}
.reg-input-pos .formText, .reg-input-pos .react-tel-input {
    border-radius: 6px !important;
}
.com-conf{
 
}
.com-input-m{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
@media screen and(max-width: 786px){
    .com-conf{
        flex-direction: column !important;
    }
}