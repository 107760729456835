body {
    font-family: 'Poppins', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: normal !important;
    margin-bottom: 10px;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

.underline {
    color: #333333;
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    text-underline-position: under;
    margin-right: 10px;
    font-size: 32px;
    margin-top: 8px !important;
    margin-bottom: 10px !important;
}

.top {
    background-color: white !important;
    color: #333333 !important;
}
.logo_holder{
    height: 55px;
}
.logo_holder img{
width: 100%;
height: 100%;
object-fit: contain;
    object-position: center;

}
.menuicon {
    justify-content: flex-start;
}


.formText {
    font-family: 'Nunito' !important;
    font-size: 14px !important;
    line-height: 1.5;
    // font-weight: bold !important;
}

.creditText {
    font-family: 'Nunito' !important;
    font-size: 15px !important;
    line-height: 1.5;
    //font-weight: bold;
    font-weight: bold !important;
}

.TextField-without-border-radius fieldset {
    border-radius: 2;
}

.formTextflag {
    padding-bottom: 2px !important;
    font-family: 'Nunito' !important;
    color: #0A2638;
    font-weight: bold !important;
    font-size: 20px !important;
}

.heading {
    font-size: 45px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Nunito' !important;
    font-weight: bold;
}

.banner__items {
    height: 289;
    background: url(../assets/images/banner_ten.png);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #0A2638;
    background-position: top;
}

.searchbtn-holder {
    position: relative;
    // bottom: -50px;
    // right: 0;
    top: 22px;
}

.searchbtn-holder button.MuiButtonBase-root.MuiButton-root.MuiButton-filledPrimary.btn-search {
    background-color: #333333 !important;


    padding: 10px 20px;
    width: auto;

    height: auto !important;
}

.searchbtn-holder button.MuiButtonBase-root.MuiButton-root.MuiButton-filledPrimary.btn-search p.buttonText {
    font-size: 16px !important;
    text-transform: none;
    color: #fff !important;
}

.header_container {
    padding: 0% 5%;
}
.menu {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Tiempos' !important;
    font-weight: 'bold';
}

.stepLabel {
    font-family: 'Nunito' !important;
    font-size: 20px !important;
    color: #0A2638 !important;
}

header.header-navbar {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 10px;
    box-shadow: none;
}

header.header-navbar .navbar-menu {
  
    justify-content: flex-end;
    padding: 0px 20px;
}

header.header-navbar .navbar-menu span {
    margin-right: 10px;
}

header.header-navbar .navbar-menu span button {
    padding: 10px;
    line-height: normal;
    font-weight: bold;
    text-transform: none;
}

header.header-navbar .navbar-menu span p {
    font-family: 'Nunito';
    background-color: transparent;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

header.header-navbar .navbar-menu span p.active {
    font-family: 'Nunito';
    /* background-color: #c07a1b; */
    color: black;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    border-bottom: 2px solid #c07a1b;
}

header.header-navbar .navbar-menu span p.inactive {
    font-family: 'Nunito';

    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

header.header-navbar .user-navbarmenu {
    display: flex;
    align-items: center;

}

.user-navbarmenu .loginmenu {

    font-family: 'Nunito';
    text-align: center;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #c07a1b;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold !important;
}

.user-navbarmenu .registermenu {

    font-family: 'Nunito';
    text-align: center;
    display: flex;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #c07a1b;
    border-radius: 5px;
}


.user-navbarmenu .registermenu button.regiclass {
    padding: 0px;
    background-color: transparent;
    color: #000;
    text-transform: none;
    font-family: 'Nunito' !important;
    text-align: center;
    font-weight: bold !important;
    cursor: pointer;
    line-height: normal;
    font-size: 1em;
}

.MenuText {
    background-color: #333333 !important;
    color: #FFFFFF !important;
}

.settingsactive {
    font-family: 'Nunito' !important;
    background-color: #c07a1b !important;
    color: white;
    text-align: center !important;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}

.settingsinactive {
    font-family: 'Nunito' !important;
    background-color: white !important;
    color: #333333;
    text-align: center;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}

.Mui-active {
    color: #333333 !important;
    text-decoration: underline #c07a1b 2px;
    text-underline-position: under;
    font-size: 15px !important;
    font-family: 'Nunito' !important;
}

.Mui-completed {
    font-size: 17px !important;
    font-family: 'Nunito' !important;
}

.MuiStepLabel-label {
    font-weight: bold !important;
    color: #333333 !important;
    font-size: 12px !important;
    //font-size: 17px !important;
    font-family: 'Nunito' !important;
    padding-top: 15%;

}

.vehiclename {
    font-family: 'Nunito' !important;
    font-weight: bold !important;
    color: #333333;
    font-size: 25px !important;
}

.MuiStep-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tripsdiv {
    border-top-left-radius: 20;
    border-top-right-radius: 20;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebar {
    height: 200px;
    width: 350px;
    background-color: #3c3c3c;
    overflow-y: auto;

    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* hide scrollbar for chrome, safari and opera */
.sidebar::-webkit-scrollbar {
    display: none;
}

.inputRounded .MuiOutlinedInput-root {
    border-radius: 6px;
}

.MuiSelect-select {
    padding-right: 0px !important;
    padding-bottom: 0px!important;
    height: 30px!important;
    line-height: 30px !important;
}

.react-tel-input .form-control {
    font-size: 12px !important;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px 14px 7px 45px !important;
    width: 99% !important;
    height: 32px !important;
}

.react-tel-input .selected-flag .flag {
    top: 50% !important;
}

.react-datepicker {
    position: absolute !important;
    right: -290px !important;
    width: max-content;
}

.dateClassfeild {
    padding: 5 !important;

}

.texttimePicker {
    padding-top: 2 !important;
}

.texttimePicker input {

    padding: 0px;
    font-size: inherit;
}

.workflow_holder {
    position: relative;
}

.workflow_box {
    position: absolute;
    top: -130px;
}
.MuiGrid-root.MuiGrid-container.container_topspace{
    margin-top: 30px;
}
.workflow_box .workflow_box_inner {
    background: white;
    padding: 5px 0px;
    box-shadow: 0 0 15px 0px #0000004f;
    border-radius: 10px;
    width: 45%;
}
.workflow_box .workflow_box_inner .workflow_icon{
    height: 50px;
    width: 50px;
}

.MuiStepper-root.MuiStepper-horizontal{
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: center;

}
.MuiStep-root.MuiStep-horizontal{
    display: block;
    position: relative;
}

.MuiStepConnector-root.MuiStepConnector-alternativeLabel{

}
.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel{
    top: calc(50% - 10px);
    left: calc(-40%);
    width: 60px;
}
.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel.Mui-active .MuiStepConnector-line{
    width: 100%;
    min-width: 100%;
    background-size: 130%;
    background-position: center;
    min-height: 24px;
    margin-top: 0px;
    border: none;
    // border-top: 2px dashed #c07a1b;
}
.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel.Mui-completed .MuiStepConnector-line{
    width: 100%;
    min-width: 100%;
    background-size: 130%;
    background-position: center;
    min-height: 24px;
    margin-top: 0px;
    border: none;
    // border-top: 2px dashed #c07a1b;
}
.MuiStep-root.MuiStep-horizontal .MuiStepConnector-root.MuiStepConnector-alternativeLabel .MuiStepConnector-line{
  
    width: 100%;
    margin: auto;
    margin-top: 8px;
    border-top: 2px dashed #a1a1a1;
}
.MuiStep-root.MuiStep-horizontal .MuiStepButton-root{
    padding: 10px 20px;
    margin: 0px;
    width: auto;
    display: block;
    height: auto;
}

.MuiStepLabel-root.MuiStepLabel-horizontal{
    display: flex;
}

.MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer{


}
.workflow_box .workflow_box_inner .MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer > div{
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 5px;
}
.workflow_box .workflow_box_inner .MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-iconContainer > div .workflow_icon{
    height:100%;
    width: 100%;
    object-fit: contain;
}
.MuiStepLabel-root.MuiStepLabel-horizontal .MuiStepLabel-labelContainer .MuiStepLabel-label{
    margin: 0px;
    padding: 0;
}
#footer {
    padding-top: 15px;
    color: white;
    /* font-size: 13px; */
    background-color: #1c1c1c;
}
.footer-con{
    padding: 20px 0px;
}

#footer p {
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: normal;
}

#footer p.link {
    cursor: pointer;
}

.column_title {
    margin-top: 0px;
    margin-bottom: 10px;
}

#footer a {
    margin-bottom: 5px;
    font-size: 13px;
    display: block;
    text-decoration: none;
    color: #fff;
}

.logo img {
    width: 75%;
    height: 100%;
    object-fit: contain;
}

#footer .copy-right {
    background: black;
    padding: 10px;
    margin-top: 10px;
}

#footer .copy-right p {
    color: white;
    text-align: center;
    font-size: 12px;
    margin: 0;
    width: 100%;
}

.MuiModal-root .MuiBox-root {
    border: none;
    background-color: transparent;
}

.MuiModal-root .MuiButtonBase-root.MuiIconButton-root {
    background-color: #fff;
    border-radius: 100px;
}
.topup_box{
    padding: 10px!important;
    background-color: #ffffff!important;
    border-radius: 15px;
}
.shadow_box {
    background: white;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ddd;
    margin: auto;
    position: relative;
    margin-bottom: 15px;
}

.shadow_box .box_title {
    position: relative;
    left: 0;
    top: 0px;
    border-radius: 0;
    padding: 5px 5px;

}

.shadow_box .box_title p,
.shadow_box .box_title p.MuiTypography-root {
    color: #000;
    font-family: 'Nunito';
    font-size: 22px!important;
    font-weight: bold;
    line-height: normal;
}

.shadow_box .box_title p.MuiTypography-root .usermenu button.MuiButtonBase-root {
    font-family: 'Nunito';
    background-color: white;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

.usermenu button.MuiButtonBase-root p.MuiTypography-root {
    font-family: 'Nunito';
    background-color: transparent;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

.usermenu p.MuiTypography-root {
    font-family: 'Nunito';
    background-color: transparent;
    color: #333333;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
}

button.primary-btn,
button.MuiButtonBase-root.MuiButton-filledPrimary {
    background-color: #c07a1b !important;
    color: #000 !important;
    border-radius: 6px !important;
    padding: 10px 20px !important;
    height: auto !important;
}

button.primary-btn:hover,
button.MuiButtonBase-root.MuiButton-filledPrimary:hover {
    background-color: #b77a2a !important;
    color: #ffffff !important;
    border-radius: 6px !important;
    padding: 10px 20px !important;
    height: auto !important;
}

button.primary-btn .MuiTypography-root,
button.MuiButtonBase-root.MuiButton-filledPrimary .MuiTypography-root {
    color: #000;
}

button.primary-btn:hover .MuiTypography-root,
button.MuiButtonBase-root.MuiButton-filledPrimary:hover .MuiTypography-root {
    color: #ffffff;
}

.MuiFormGroup-root .MuiTypography-root {
    font-size: 14px;
    top: 15px !important;
    left: 15px !important;
}

.MuiFormGroup-root .MuiFormControl-root.MuiTextField-root,
.MuiFormGroup-root .react-datepicker__input-container .MuiFormControl-root.MuiTextField-root {
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #c07a1b !important;
    border-radius: 6px ;
    width: auto !important;
    height: auto !important;
    padding: 6px 10px !important;
    // padding-top: 13px !important;
    display: block;
}

.MuiFormGroup-root .MuiFormControl-root {
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #c07a1b !important;
    border-radius: 6px !important;
    width: auto !important;
    height: auto !important;
    padding: 10px !important;
    padding-top: 10px !important;
    display: block;
}

.MuiFormGroup-root .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    width: 100%;
    border: none !important;
}

.MuiFormGroup-root .react-tel-input {
    background-color: rgb(255, 255, 255) !important;
    border: 1px solid #666666 !important;
    border-radius: 6px !important;
    width: auto !important;
    height: auto !important;
    padding:5px 10px !important;
 
    display: block;
}

.MuiFormGroup-root .react-tel-input .flag-dropdown {
    border: none;
    border-right: 1px solid #666666;
}

.MuiFormGroup-root .react-tel-input .form-control {
    border: none;
}

button.MuiButtonBase-root.MuiTab-root {
    color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    margin: 5px 0px 4px 0px;
    background-color: #333333;
    padding: 10px 20px;
    height: 47px !important;
    text-transform: capitalize;
    min-height: auto !important;
    line-height: normal;
    max-width: 100% !important;
}

button.MuiButtonBase-root.MuiTab-root.tabheight {
    color: #ffffff;
    border-radius: 6px;
    margin: 5px 5px 5px 0px;
    background-color: #333333;
    padding: 10px 20px;
    height: 30px !important;
    text-transform: capitalize;
    min-height: auto !important;
    line-height: normal;
    max-width: 100% !important;
}

button.MuiButtonBase-root.MuiTab-root.Mui-selected {
    color: #000000;
    background-color: #c07a1b;
}

.MuiTabs-scroller .MuiTabs-indicator {
    display: none !important;
}

.MuiToggleButtonGroup-root.toggleRadio {
    margin-bottom: 10px;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root {
    background-color: transparent;
    position: relative;
    padding-left: 30px;
    font-size: 14px !important;
    height: auto;
    line-height: normal;
    padding-top: 7px;
    padding-bottom: 7px;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #c07a1b;
    border: 2px solid #333333;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root.Mui-selected {
    background-color: transparent;
    color: #000000;
}

.MuiToggleButtonGroup-root.toggleRadio button.MuiToggleButton-root.Mui-selected:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 8px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #000000;
    border: none;
    z-index: 1;
}



.vehicleBox {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    flex-direction: row;
    justify-content: space-between;
    // flex-wrap: wrap;
}

.vehicleBox_img {
    width: 100%;
    height: 200px;
}

.vehicleBox_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.vehicleBox_details {
    width: 100%;
    padding: 10px 10px;
    // border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    position: relative;
}

.sum_listitem {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: 1%;
    margin-bottom: 10px;
}

.img_icon {
    background-color: #ffffff;
    height: 30px;
    width: 30px;
    padding: 4px;
    border-radius: 6px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.img_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.listitem_value {
    display: block;
}

.descriptionList {

    padding: 10px 0px;
    display: flex;
    flex-direction: row;

}

.descriptionList .descriptionItem {
    display: flex;
    align-items: center;
}

.descriptionList .descriptionItem .descriptionItemImg {
    width: 20px;
    height: 20px;
    object-fit: contain;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 6px;

}

.descriptionList .descriptionItem p.descriptionText {
    padding: 5px !important;
    color: #7a7a7a !important;
    font-size: 14px !important;
}

.description_holder {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
}

.vehicle_price_holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.vehicle_price_holder p.pricetext {
    font-size: 15px !important;
    color: #000000;
    font-family: 'Nunito' !important;
    font-weight: bold;
    font-weight: 700 !important;
    text-align: left;
    // position: absolute;
    // top: 0px;
    // left: 90px;
}

.tripsummary_holder {
    
}
.sum_list{
    // background-color: #c07a1b;
    // border: 1px solid #ddd;
    // border-radius: 10px;
    // margin-top: 15px;
    // padding: 10px;
}
.dropdown_formcontrol {
    background-color: white !important;
    border: 1px solid #c07a1b !important;
    border-radius: 6px !important;
    width: auto !important;
    height: auto !important;
    padding: 6px 10px !important;

    display: block;
}

.texttimePicker {}

button.newbooking {
    color: white;
    background-color: rgb(51, 51, 51);
    border-radius: 6px;
    height: 30px;
    padding: 5px 10px;
}

.username_menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px;
    border-radius: 6px;
    background-color: transparent;
}

.username_menu .MuiAvatar-root {
    margin-right: 10px;
    background-color: #b77a2a;
    height: 30px;
    width: 30px;
}
.close-btn-admin{
    width: 25px;
    height: 25px;
    margin-left: 15px !important;
}
.booking_box {
    border-radius: 0px 10px 10px 10px !important;
    background-color: #c07a1b;
    border: 1px solid #c07a1b;
    margin-bottom: 20px;
}

.wallet_box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}

.wallet_amt {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    width: 40%;
    background-color: #c07a1b;
    align-items: center;
}

.topup_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.MuiList-root.MuiList-padding.MuiMenu-list {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    padding: 10px;
    justify-content: center;
    flex-wrap: wrap;
   
}

.MuiList-root.MuiList-padding.MuiMenu-list>div {
    width: 100%;
}

.MuiList-root.MuiList-padding.MuiMenu-list>div .MuiSvgIcon-root {
    border-radius: 50px !important;
}

.location_point {
    background-color: #c07a1b;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-top: 0;
    padding: 10px;
}

.location_point p.loctext{
    text-align: center;
    margin-bottom: 10px;
    background-color: #c07a1b;
    border-radius: 6px;
    padding: 5px 0px;
    font-weight: bold;
}

.MuiCardContent-root.card_content {
    border-radius: 10px;
    padding: 0px !important;
    border: 2px solid #c07a1b;
    margin-bottom: 20px;
}
.container_topspace{
    margin-top: 30px!important;
}



.triptype_title {
    background-color: #c07a1b;
    color: #000;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-family: 'Nunito';
    padding: 10px 42px 10px 10px;
    position: relative;
    font-size: 20px;
}
.location_startpoint{
    display: block;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 40%;
}
.location_stoppoint{
    display: block;
    justify-content: space-around;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 40%;
}
.location_startpoint .location_innerbox,
.location_stoppoint .location_innerbox{
width: 100%;
}
    .location_midpoint{
        border: none;
        margin-left: 10px;
        min-height: 2px;
        width: 20%;
        height: 1px;
        background-color: #c07a1b;
}
.mainstop {
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
    align-items: baseline;
    flex-wrap: wrap;
}
.location_spot{
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    width: 100%;
    justify-content: space-around;

}

.location_innerbox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    align-content: flex-start;

}
.location_innerbox .img_icon{
margin: 0;
}
.triptype_name{
    padding: 0px 10px;
    margin: 5px auto;
    font-size: 15px;
    border-radius: 5px;
    font-weight: bold;
}
.location_innerbox .loctext{

    font-size: 14px;
    text-align: left;
    width: 100%;
}
.total_fare{
 text-align: right;
    padding: 10px;
    background-color: #b77a2a;
    color:#fff;
}
.total_fare p{
    font-size: 22px;
}
// my changes
.con{
    display: flex;
    flex-direction: row;
}
.left-side img{
    width: 100%;
}
.head_section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}
.customer-form-con{
    position: relative;
    height:100vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
button.MuiButtonBase-root.MuiButton-filledPrimary{
    padding: 10px 50px;
}
.ride-s-h{
    color: #b77a2a;
    margin-top: 0px;
}
.ride-h{
    margin-top: 0px;
    margin-bottom: 0px;
}
.desc-p{
    width: 300px;
}
.desc-p p{
    font-size: 14px;
    color: #5d5959;
}
button.MuiButtonBase-root.MuiButton-filledPrimary{
    padding: 12px 34px !important;
    font-weight: bold;
}
.star{
    width: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.star img{
    width: 100%;
    margin-right: 5px;
}
button.MuiButtonBase-root.MuiButton-filledPrimary:hover{
    padding: 12px 34px !important;
}
// .css-16kairg-MuiStepConnector-root.Mui-completed .MuiStepConnector-line, .css-16kairg-MuiStepConnector-root.Mui-active .MuiStepConnector-line{
//     background-image: none !important;
// }
.footer-a{
    width: 370px;
}
.input-con{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding: 1px 0px;
    border-radius: 5px;
    font-size: 14px;
}
.sum-i{
    background: none;
}
.sum_list{
    // display: flex;
    // flex-direction: row;
    // align-items: center;
}
.sum-main-hol{
    // background-color: #c07a1b;
    // width: 100%;
    // margin-top: 15px;
    // border-radius: 0px;
}
.sum-inside{
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // width: 90%;
    // margin: auto;
    // border-radius: 0px;
}
.loc-p-n{
    border: none;
    padding: 0px;
}
.pic-h{
    font-size: 14px !important;
    color: #000000;
    line-height: normal !important;
}
.list-n{
    // width: 300px;
}
.start-point-n{
    width: 48%;
}
.p-pos{
    position: relative;
    // top: -10px;
    color: #000000 !important;
    font-weight: 400 !important;
}
.country-h{
    height: 45px !important;
}

.cus-log-bg-con{
    display: flex !important;
    /* flex-direction: column !important; */
    padding: 0px !important;
    align-items: stretch;
    /* margin: 30px auto; */
    /* width: 40%; */
    /* box-shadow: 0px 2px 10px #0077bd; */
    /* border-radius: 10px; */
    /* margin-bottom: 10px; */
    overflow: hidden;
    /* flex-wrap: wrap;*/
}

.reg-sub-h{

    padding-left: 20px;
   
}
.color-h{
    color: #c07a1b;
}
.reg-sub-h h2{
    font-size: 30px;
    font-weight: bold !important;
}
.reg-sub-h h4{
    font-size: 23px;
    font-weight: bold !important;
    color: #c07a1b;
    margin-top: 0px;
}
.reg-sub-h p{
    font-size: 18px;
    font-weight: 700;
}
.enter-first-name input{
    color: #000000;
}
.enter-first-name .formText, .enter-first-name .react-tel-input {
    border-radius: 6px !important
}
.enter-first-name{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}
.reg-img-con{
    position: relative;
}
.reg-side-bg{
    position: absolute;
    top: 0px;
    display: none;
}
.bottom-bg-p{
    position: absolute;
    top: -85px;
    left: -762px;
    display: none;
}
.bottom-bg-p p{
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 20px;
    color: #c07a1b;
}
.bottom-bg-p span{
    position: absolute;
    top: 40px;
    left: 10px;
}
.bottom-bg-p img{
    width: 630px;
}
.reg-side-bg{
    top: -158px;
    right: 0px;
    left: 209px;
    position: absolute;
}
.reg-side-bg img{
    width: 550px;
}
.car-sel{
    padding-top: 30px;
}
.tripsummary_holder{
    // position: absolute;
    // top: 328px;
 
    width: 100%;
    background-color: #c07a1b;
}
.log-bg{
    height: 100vh;
    width: 50%;
    overflow: hidden;
}
.log-bg img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.log-ico{
    width: 210px;
   
    background-color: #fff;
    padding: 10px;
    padding-right: 20px;
    
    border-radius: 0px 0px 30px 0px;
   
}
.log-ico img{
    width: 100%;
}

.full_contentholder {
    padding: 20px 40px;
}

.row_title {
    font-size: 24px;
}

.row_para {
    margin-bottom: 20px;
    text-align: justify;
}

.row_list {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.row_list li {}

.readmore_link {
    cursor: pointer;
    color: #c07a1b;
}

.our_service {
    padding: 40px
}

.our_service .section_heading {
    text-align: center;
    margin-bottom: 20px;
}

ul.service_list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

ul.service_list>li {
    width: 46%;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 10px;
}

ul.service_list>li .topic_heading {
    margin: 0px;
    margin-bottom: 15px;
    line-height: normal;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

ul.service_list>li ul.topic_point_list {}
.section_heading {
    font-size: 36px;
    margin: 0;
    margin-bottom: 10px;
}
.leftside_textholder {
    padding: 40px;
}

.rightside_textholder {
    padding: 40px;
}

.leftside_imgholder {}

.leftside_imgholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.rightside_imgholder {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.rightside_imgholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.about_sec4 {
    padding: 40px 0px;
}

.about_sec4 .section_heading {
    text-align: center;
}

.box_content {
    text-align: center;
    padding: 20px;
}

.icon_img {
    width: 80px;
    margin: 10px auto;
}

.icon_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

ul.service_point li {
    font-size: 16px;

}

.box_heading {
    font-size: 26px;
    margin: 10px 0px;
}

.pick-loc{
    margin-bottom: 10px !important;
}
.pick-loc input{
    height: 25px;
}
.drop-loc input{
    height: 25px;
}
.pass-loc input{
    height: 25px;
}
.point-pick input{
    height: 25px !important;
    // margin-bottom: 10px !important;
}
.passngr-p > .selectpassenger > div > .MuiFormGroup-root .MuiFormControl-root{
    height: 30px !important;
}
.drp-loc-h{
    margin-bottom: 10px !important;
}
.frm-m{
    margin-bottom: 10px !important;
}
.duration input{
    height: 32px;
}
.hourly-srch{
    top: 5px;
}
.hourly-srch button{
    height: 47px !important;
}
.point-srch{
    top: 21px;
}
.point-srch button{
    height: 20px !important;
}
.point-srch button p{
    display: flex;
}
.guest-log{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.guest-log-img{
    width: 100%;
    height: 610px;
}
.guest-log-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.guest-p{
    padding-top: 0px !important;
}
.cus-reg{
    width: 90% !important;
    margin: 0px auto !important;
}
.sumry-con{
    position: relative;
    top: 100px;
}
.login-logo{
    width: 200px;
    margin-top: 10px;
}
.login-logo img{
    width: 100%;
}

.goog-btn{
    background-color: #4286f5 !important;
}
.goog-btn:hover{
    background-color: #609dff !important;
}
.goog-btn p{
    color: #fff !important;
}
.cus-reg-btn{
    background-color: #c07a1b !important;
}
.button.btn_login:hover, .cus-reg-btn:hover{
    background: #b77a2a !important;
    color: #fff;
}
.amnt-input input{
    height: 36px;
}
.basic-menu{
    background-color: #fff !important;
}
.cus-menu{
    background-color: #ffffff !important;
    color: #000 !important;
}
.cus-menu:hover{
    background-color:  #000 !important;
    color: #fff !important;
}
.brnch-code-input input{
    height: 35px;
}
.deposite-in-h input{
    height: 27px;
}
.upload-rec-h input{
    height: 30px
}
.duration-in .MuiFormGroup-root .MuiFormControl-root.MuiTextField-root{
    height: 100px;
}
.hourly-p-d, .duration-in{
    margin-bottom: 10px !important;
}
.hourly-psngr{
    margin-bottom: 10px !important;
}
.hourly-srch {
    margin-bottom: 5px !important;
}

@media screen and(max-width: 786px){
    .footer-con{
        flex-direction: column !important;
    }
    .logo{
        width: 200px;
    }
    .footer-w{
        max-width: 90% !important;
    }
    .vehicleBox{
        flex-direction: column;
        padding: 20px;
    }
    .guest-log{
        flex-direction: column !important;
        padding: 0px 0px 100px 0px;
    }
    .guest-log-img{
        display: none;
    }
    .log-bg-img{
        display: none;
    }
    .customer-form-con{
        padding-bottom: 0px !important;
    }
    .cus-reg-bg{
        display: none;
    }
    .log-bg{
        display: none;
    }
    .profilr-img{
        display: none;
    }
    .pro-frm{
        flex-direction: column;
    }
    .point-srch {
        top: 6px;
    }
    .wallet_amt{
        width: 100%;
    }
    .add-user-pop{
        width: 90%;
    }
    .container_topspace .col-reverse-responsive{
        flex-direction: column-reverse;
    }
    .tobar-dis{
        display: flex;
        padding: 0px 5px;
    }
    .tobar-dis button{
        color: #000;
        font-weight: normal;
        text-transform: capitalize;
        font-size: 1rem;
    }
    .newuser-dis{
        width: 90% !important;
    }
    .userli-new{
        width: auto !important;
        min-width: auto !important;
        max-width: fit-content !important;
    }
}

@media screen and(max-width: 480px){

    .header_container{
        padding: 0px;;
    }
}

.time-date-mar{
    margin-right:5px;
}
.padding-pass .MuiFormGroup-root .MuiFormControl-root {
    // height: 34px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 13px !important;
}
.padding-pass .MuiFormGroup-root .MuiTypography-root{
        top: 14px !important;
}
.mar-date-pick input{
    position: relative;
    top: 4px;
}
.bg-logo-add{
    background-color: #ffffff;
    padding: 0 10px;
    border-radius: 5px;
    height: 100px;
}
.bg-logo-add img{
    width:100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}